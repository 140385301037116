<!--
 * @Description: 优惠活动
 * @Author: 琢磨先生
 * @Date: 2022-06-19 00:15:23
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-02 15:01:24
-->

<template>
  <!-- <el-card class="box">
    <el-button type="primary" size="small" @click="onInit" round icon="plus"
      >初始化等级</el-button
    >
  </el-card>-->
  <el-card class="box data" v-loading="loading">
    <el-table :data="tableData" border>
      <el-table-column label="类型" prop="type_text" width="120"></el-table-column>
      <el-table-column label="有效时长" width="120">
        <template #default="scope">
          <div v-if="scope.row.is_allow">用户设置</div>
          <div v-if="!scope.row.is_allow">
            <div v-if="scope.row.is_for_ever">长期有效</div>
            <div v-else>
              <div>
                开始日期：{{
                scope.row.start_date ? scope.row.start_date : "不限制"
                }}
              </div>
              <div>结束日期：{{ scope.row.end_date }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="可延长" width="100">
        <template #default="scope">{{ scope.row.lengthen_days }}天</template>
      </el-table-column>
      <el-table-column label="梯度数" width="100">
        <template #default="scope">
          <span>{{ scope.row.stairway_num }}</span>
        </template>
      </el-table-column>
      <el-table-column label="优惠方式" prop="way_text" width="100"></el-table-column>
      <el-table-column label="则扣范围" width="140">
        <template #default="scope">
          <div>最小折扣：{{ scope.row.value_min }}</div>
          <div>最打折扣：{{ scope.row.value_max }}</div>
        </template>
      </el-table-column>
      <el-table-column label="最大抵扣积分" prop="score_max" width="120"></el-table-column>
      <el-table-column label="可叠加" width="100">
        <template #default="scope">
          <div v-if="scope.row.is_multiple">是</div>
        </template>
      </el-table-column>

      <el-table-column label="停用" width="100">
        <template #default="scope">
          <el-tag type="danger" v-if="scope.row.is_stop" size="small">停用</el-tag>
          <el-tag type="success" v-else size="small">正常</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="全场通用" width="100">
        <template #default="scope">
          <div v-if="scope.row.is_general">是</div>
        </template>
      </el-table-column>

      <el-table-column label="通用梯度" width="240">
        <template #default="scope">
          <div v-if="scope.row.is_general && scope.row.items" class="general_wrap">
              <el-tag   size="small" v-for="(item, index) in scope.row.items" :key="index" style="margin-right:10px;margin-bottom:10px;">
                <span v-if="scope.row.type==1">最大抵扣{{item.decimal_score}}%</span>
              </el-tag> 
          </div>
        </template>
      </el-table-column>

      <el-table-column label="描述说明" prop="description" width="300"></el-table-column>

      <el-table-column label="操作" fixed="right" width="100">
        <template #default="scope">
          <el-button type="primary" link size="small" @click="onEdit(scope.row)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>

  <!-- 编辑弹框 -->
  <el-dialog :title="`【${form.type_text}】活动配置`" v-model="visibleDialog" draggable width="700px"
   :close-on-click-modal="false" :close-on-press-escape="false" 
 >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="允许设置日期" v-if="form.type!=1">
        <el-switch v-model="form.is_allow"></el-switch>
      </el-form-item>

      <el-form-item label="永久有效" prop="is_for_ever" v-if="!form.is_allow && form.type!=1">
        <el-switch v-model="form.is_for_ever"></el-switch>
      </el-form-item>

      <el-form-item label="开始日期" v-if="!form.is_for_ever && !form.is_allow && form.type!=1">
        <el-date-picker
          clearable
          v-model="form.start_date"
          value-format="YYYY-MM-DD"
          placeholder="请选择"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="结束日期" v-if="!form.is_for_ever && !form.is_allow">
        <el-date-picker v-model="form.end_date" value-format="YYYY-MM-DD" placeholder="请选择"></el-date-picker>
      </el-form-item>

      <el-form-item label="自动延长天数" prop="lengthen_days"  v-if="form.type!=1">
        <el-col :span="5">
          <el-input v-model="form.lengthen_days" placeholder></el-input>
        </el-col>
        <div class="help-block">当用户选择自动延长时可延长的天数</div>
      </el-form-item>

      <el-form-item label="优惠方式" v-if="form.type != 1">
        <el-radio-group v-model="form.way">
          <el-radio label="0">打折</el-radio>
          <!-- <el-radio :label="1">立减</el-radio> -->
          <!-- <el-radio :label="2">打折或立减</el-radio> -->
        </el-radio-group>
      </el-form-item>
      <el-form-item label="可设梯度数" v-if="[2, 3, 4].find((x) => x == form.type)">
        <el-col :span="10">
          <el-input v-model="form.stairway_num" placeholder></el-input>
        </el-col>
        <div class="help-block">连住优惠，早鸟特惠，今夜特价时使用</div>
      </el-form-item>

      <el-form-item label="最小折扣" v-if="form.way != 1 && form.type != 1" prop="range_min">
        <el-col :span="10">
          <el-input v-model="form.value_min" placeholder></el-input>
        </el-col>
        <div class="help-block">只能填写1.0~9.9范围</div>
      </el-form-item>
      <el-form-item label="最大折扣" v-if="form.way != 1 && form.type != 1" prop="range_max">
        <el-col :span="10">
          <el-input v-model="form.value_max" placeholder></el-input>
        </el-col>
        <div class="help-block">只能填写1.0~9.9范围</div>
      </el-form-item>

      <!-- <el-form-item label="最大抵扣积分" v-if="form.type == 1" prop="score_max">
        <el-col :span="10">
          <el-input v-model="form.score_max" placeholder></el-input>
        </el-col>
        <div class="help-block">只能填写整数</div>
      </el-form-item> -->

      <!-- <el-form-item label="可叠加">
        <el-switch v-model="form.is_multiple"></el-switch>
      </el-form-item>-->
      <el-form-item label="描述说明">
        <el-col :span="20">
          <el-input v-model="form.description" type="textarea" placeholder :rows="4"></el-input>
        </el-col>
        <div class="help-block">描述内容应由系统管理员编写</div>
      </el-form-item>

      <el-form-item label="停用">
        <el-switch v-model="form.is_stop"></el-switch>
      </el-form-item>

      <el-form-item label="抵扣比例" v-if="form.type==1">
        <el-input v-model="form.items[0].decimal_score" placeholder="请输入抵扣比例" style="width:200px;">
          <template #suffix>%</template>
        </el-input>
        <!-- <el-table :data="form.items" border>
          <el-table-column label="梯度">
            <template #default="scope">
              <el-input v-model="scope.row.score" placeholder="请输入积分" style="width:200px;"></el-input>
            </template>
          </el-table-column>
          <el-table-column label width="100">
            <template #header>
              <el-button
                icon="Plus"
                type="primary"
                circle
                size="small"
                @click="form.items.push({
                score:'',
              })"
              ></el-button>
            </template>
            <template #default="scope">
              <el-button
                type="danger"
                link
                icon="remove"
                @click="form.items.splice(scope.$index,1)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table> -->
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving">确定</el-button>
    </template>
  </el-dialog>
</template>
<script>
import common_api from "@/http/common_api";
export default {
  components: {},
  data() {
    return {
      loading: false,
      visibleDialog: false,
      saving: false,
      tableData: [],
      form: {
        items: [],
      },
      rules: {
        value_min: [
          {
            pattern: /^0|[1-9](\.\d{0,2})?$/,
            message: "请输入",
            trigger: "blur",
          },
        ],
        value_max: [
          {
            pattern: /^0|[1-9](\.\d{0,2})?$/,
            message: "请输入",
            trigger: "blur",
          },
        ],
      },
      menus: {},
    };
  },
  created() {
    this.loadData();
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.menus = res.data;
      }
    });
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.$http.get("admin/v1/activity").then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },

    /**
     * 新增、修改
     */
    onEdit(item) {
      this.visibleDialog = true;
      this.form = Object.assign({}, item);
      // if (item.type == 1) {
      //   if (!item.items) {
      //     this.form.items = [];
      //   }
      // }
    },
    /**
     * icon图片上传
     */
    uploadChange(url) {
      this.form.icon = url;
    },
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("/admin/v1/activity/edit", this.form)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.visibleDialog = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.form = {};
                this.loadData();
              }
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
  .general_wrap{
    display: flex;
    flex-wrap: wrap;
  }
</style>